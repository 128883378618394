import { createClient } from "urql";
import log from "modules/log";

export var urqlClient = createClient({
  url: process.env.REACT_APP_API_URL,
  fetchOptions: {
    credentials: "include",
  },
});

export const urqlClientNoCache = createClient({
  url: process.env.REACT_APP_API_URL,
  fetchOptions: {
    credentials: "include",
  },
  requestPolicy: "network-only",
});

export const resetClient = () => {
  urqlClient = createClient({
    url: process.env.REACT_APP_API_URL,
    fetchOptions: {
      credentials: "include",
    },
  });
};

export class Client {
  constructor() {
    this.mutationClient = createClient({
      url: process.env.REACT_APP_API_URL,
      fetchOptions: {
        credentials: "include",
      },
      requestPolicy: "network-only",
    });
  }

  run = (query, args = {}) => {
    return urqlClient
      .query(query, args)
      .toPromise()
      .then((result) => {
        if (result.error) {
          log.error(`GQL Server response error: ${result.error}`);
          return;
        }
        return result.data;
      });
  };

  runNoCache = (query, args = {}) => {
    return urqlClientNoCache
      .query(query, args)
      .toPromise()
      .then((result) => {
        if (result.error) {
          log.error(`GQL Server response error: ${result.error}`);
          return;
        }
        return result.data;
      });
  };

  mutation = (query, args = {}) => {
    return this.mutationClient
      .mutation(query, args)
      .toPromise()
      .then((result) => {
        if (result.error) {
          log.error(`GQL Server response error: ${result.error}`);
          return;
        }
        return result.data;
      });
  };
}

export default Client;
